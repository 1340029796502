import React from "react"
import { graphql } from "gatsby"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import Layout from "../components/layout"
import Container from "../components/container"
import Seo from "../components/seo"
import { convertRaw, renderRawText } from "../helpers/rich-text"

const Datenschutz = ({
  path,
  data: {
    page: { title, text },
  },
}) => {
  return (
    <Layout path={path}>
      <Seo title="Datenschutz" />
      <Container verticalSpaceSm>
        <h1 className="text-2xl text-gray-600 tracking-widest mb-12">{title}</h1>
        <div className="md:columns-3">
          <div className="text-xs text-gray-500 tracking-widest">
            {documentToReactComponents(convertRaw(text), renderRawText)}
          </div>
        </div>
      </Container>
    </Layout>
  )
}

export default Datenschutz

export const pageQuery = graphql`
  query DatenschutzPage {
    site {
      siteMetadata {
        siteUrl
      }
    }
    page: contentfulDatenschutz(
      id: { eq: "39331dbe-88dd-5953-a0a5-3064a504094c" }
    ) {
      title
      text {
        raw
      }
    }
  }
`
